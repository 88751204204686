/*!
 * AdminKit v3.4.0 (https://adminkit.io/)
 * Copyright 2023 Paul Laros
 * Copyright 2023 AdminKit
 * Licensed under MIT (https://github.com/adminkit/adminkit/blob/master/LICENSE)
 */

// Variables
@import "1-variables/app";

// Bootstrap
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/utilities/api";

// Theme mixins
@import "2-mixins/button";

// Theme components
@import "3-components/avatar";
@import "3-components/buttons";
@import "3-components/card";
@import "3-components/chart";
@import "3-components/content";
@import "3-components/dropdown";
@import "3-components/feather";
@import "3-components/footer";
@import "3-components/hamburger";
@import "3-components/list-group";
@import "3-components/main";
@import "3-components/navbar";
@import "3-components/reboot";
@import "3-components/sidebar";
@import "3-components/sizing";
@import "3-components/stat";
@import "3-components/tables";
@import "3-components/type";
@import "3-components/wrapper";

// Theme utilities
@import "4-utilities/cursors";

// 3rd party plugins
@import "~jsvectormap/dist/css/jsvectormap";
@import "~simplebar/dist/simplebar";
@import "~flatpickr/dist/flatpickr";

// 3rd party plugin styles
@import "5-vendor/flatpickr";
@import "5-vendor/simplebar";

@import "fontawesome";

.text-orange {
    color: var(--bs-orange) !important;
}

.table-action {
    text-align: right;
    a {
        color: #6c757d;
        text-decoration: none;
        font-size: 1rem;
    }

    a:not(:first-child) {
        margin-left: 3px;
    }
}

.table-action a:hover {
    color: #212529
}

.display-15 {
    font-size: 1.5em;
}

.tab {
    margin-bottom: 2rem
}

.tab .nav-tabs {
    border: 0
}

.tab .nav-tabs .nav-link {
    background: transparent;
    border: 0;
    color: #343a40;
    padding: .75rem 1rem
}

.tab .nav-tabs .nav-link.active {
    background: #fff;
    color: #343a40
}

.tab .nav-tabs .nav-link:hover:not(.active) {
    color: #3b7ddd
}

.tab .nav-tabs .nav-link svg {
    height: 20px;
    width: 20px
}

.tab .tab-content {
    background: #fff;
    border-radius: 0 0 .2rem .2rem;
    box-shadow: 0 .1rem .2rem rgba(0, 0, 0, .05);
    padding: 1.25rem
}

.tab .tab-content p:last-child {
    margin-bottom: 0
}

.tab-primary .nav-tabs .nav-link.active {
    background: #3b7ddd;
    border-bottom-color: #3b7ddd;
    color: #fff
}

.tab-primary .tab-content {
    background: #3b7ddd;
    color: #fff
}

.tab-primary .h1, .tab-primary .h2, .tab-primary .h3, .tab-primary .h4, .tab-primary .h5, .tab-primary .h6, .tab-primary h1, .tab-primary h2, .tab-primary h3, .tab-primary h4, .tab-primary h5, .tab-primary h6 {
    color: #fff
}

.tab-secondary .nav-tabs .nav-link.active {
    background: #6c757d;
    border-bottom-color: #6c757d;
    color: #fff
}

.tab-secondary .tab-content {
    background: #6c757d;
    color: #fff
}

.tab-secondary .h1, .tab-secondary .h2, .tab-secondary .h3, .tab-secondary .h4, .tab-secondary .h5, .tab-secondary .h6, .tab-secondary h1, .tab-secondary h2, .tab-secondary h3, .tab-secondary h4, .tab-secondary h5, .tab-secondary h6 {
    color: #fff
}

.tab-success .nav-tabs .nav-link.active {
    background: #1cbb8c;
    border-bottom-color: #1cbb8c;
    color: #fff
}

.tab-success .tab-content {
    background: #1cbb8c;
    color: #fff
}

.tab-success .h1, .tab-success .h2, .tab-success .h3, .tab-success .h4, .tab-success .h5, .tab-success .h6, .tab-success h1, .tab-success h2, .tab-success h3, .tab-success h4, .tab-success h5, .tab-success h6 {
    color: #fff
}

.tab-info .nav-tabs .nav-link.active {
    background: #17a2b8;
    border-bottom-color: #17a2b8;
    color: #fff
}

.tab-info .tab-content {
    background: #17a2b8;
    color: #fff
}

.tab-info .h1, .tab-info .h2, .tab-info .h3, .tab-info .h4, .tab-info .h5, .tab-info .h6, .tab-info h1, .tab-info h2, .tab-info h3, .tab-info h4, .tab-info h5, .tab-info h6 {
    color: #fff
}

.tab-warning .nav-tabs .nav-link.active {
    background: #fcb92c;
    border-bottom-color: #fcb92c;
    color: #fff
}

.tab-warning .tab-content {
    background: #fcb92c;
    color: #fff
}

.tab-warning .h1, .tab-warning .h2, .tab-warning .h3, .tab-warning .h4, .tab-warning .h5, .tab-warning .h6, .tab-warning h1, .tab-warning h2, .tab-warning h3, .tab-warning h4, .tab-warning h5, .tab-warning h6 {
    color: #fff
}

.tab-danger .nav-tabs .nav-link.active {
    background: #dc3545;
    border-bottom-color: #dc3545;
    color: #fff
}

.tab-danger .tab-content {
    background: #dc3545;
    color: #fff
}

.tab-danger .h1, .tab-danger .h2, .tab-danger .h3, .tab-danger .h4, .tab-danger .h5, .tab-danger .h6, .tab-danger h1, .tab-danger h2, .tab-danger h3, .tab-danger h4, .tab-danger h5, .tab-danger h6 {
    color: #fff
}

.tab-light .nav-tabs .nav-link.active {
    background: #f5f7fb;
    border-bottom-color: #f5f7fb;
    color: #fff
}

.tab-light .tab-content {
    background: #f5f7fb;
    color: #fff
}

.tab-light .h1, .tab-light .h2, .tab-light .h3, .tab-light .h4, .tab-light .h5, .tab-light .h6, .tab-light h1, .tab-light h2, .tab-light h3, .tab-light h4, .tab-light h5, .tab-light h6 {
    color: #fff
}

.tab-dark .nav-tabs .nav-link.active {
    background: #212529;
    border-bottom-color: #212529;
    color: #fff
}

.tab-dark .tab-content {
    background: #212529;
    color: #fff
}

.tab-dark .h1, .tab-dark .h2, .tab-dark .h3, .tab-dark .h4, .tab-dark .h5, .tab-dark .h6, .tab-dark h1, .tab-dark h2, .tab-dark h3, .tab-dark h4, .tab-dark h5, .tab-dark h6 {
    color: #fff
}

.tab-title {
    font-size: .925rem
}

.tab-vertical .nav-tabs {
    flex-direction: column;
    float: left
}

.tab-vertical .nav-tabs .nav-link {
    border-bottom-left-radius: .2rem;
    border-bottom-right-radius: 0;
    border-top-left-radius: .2rem;
    border-top-right-radius: 0
}

.tab-vertical .tab-content {
    overflow: auto
}
